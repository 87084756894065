@import "./assets/styles/loading.scss";
@import "./assets/styles/mixins/spacing.mixin.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./assets/styles/typography/typography.scss";
@import "./assets/styles/theme/theme.scss";
@import "~ngx-toastr/toastr";

@import '~swiper/swiper.min.css';
@import '~swiper/modules/navigation/navigation.min.css';
@import '~swiper/modules/pagination/pagination.min.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Lato, "Helvetica Neue", sans-serif !important;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

[hidden] {
  display: none !important;
}

.error-message {
  color: rgba(255, 255, 255, 1);
  background: #a01126;
}

main {
  min-height: calc(100vh - 128px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.1px;
}

h4 {
  font-size: 20px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1420px;
}

.internal-server-error,
.page-not-found,
.not-authenticated {
  text-align: center;
  height: calc(100vh - 128px);
}

.mat-toolbar {
  background: #333;
}

.mat-header-row,
.mat-row {
  min-height: 30px;
}

.icon {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  &-instruments-directory {
    background-image: url(./assets/icons/instruments-directory.svg);
  }
  &-cv-pipeline {
    background-image: url(./assets/icons/cv-pipeline.svg);
  }
  &-group {
    background-image: url(./assets/icons/group.svg);
  }
  &-scoreboard {
    background-image: url(./assets/icons/scoreboard.svg);
  }
  &-notes {
    background-image: url(./assets/icons/notes.svg);
  }
}

// Tables
table {
  width: 100%;
}

.table-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow: auto;
  height: 80vh;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table > thead {
  vertical-align: middle;
}
.table > tbody {
  vertical-align: middle;
}
.table > :not(:first-child) {
  border-top: 0;
}

.full-screen-modal .mat-dialog-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.mat-card-header-text {
  margin: 0 !important;
}

app-image-dialog {
  width: 100%;
  height: 100%;

  .mat-toolbar {
    background: transparent;
  }
}
.item-photo {
  width: 40px;
  height: 40px;
  margin: 0;
  display: block;
  margin-top: 4px;
  cursor: pointer;
  overflow: hidden;
}

.mat-header-cell, .mat-sort-header-content {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background: #6096F6;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #6096F6;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background: #f47121;
  color: #ffffff;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #f47121;
}

.mat-button {
  &.tray-button {
    min-width: 131px;
    height: 48px;
    border-radius: 48px;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: center;

    &.medium {
      height: 42px;
      font-size: 14px;
      line-height: 32px;
    }
    &.small {
      height: 32px;
      font-size: 14px;
      line-height: 32px;
    }
    &.dark-blue {
      background-color: #1e5598;
      .mat-button-wrapper {
        color: #f2f3f8;
      }
    }
    &.blue {
      background-color: #6096f6;
      .mat-button-wrapper {
        color: #f2f3f8;
      }
    }
    &.orange {
      background-color: #f47121;
      .mat-button-wrapper {
        color: #f2f3f8;
      }
    }
    &.grey {
      background-color: #828282;
      .mat-button-wrapper {
        color: #f2f3f8;
      }
    }
    &.red {
      background-color: #f5515f;
      .mat-button-wrapper {
        color: #f2f3f8;
      }
    }
    &.transparent {
      background-color: transparent;
      .mat-button-wrapper {
        color: #97989b;
      }
    }
    &.light-grey {
      background-color: #f2f3f8;
      .mat-button-wrapper {
        color: #5b5e71;
      }
    }
    &[disabled] {
      background-color: lightgray !important;
      .mat-button-wrapper {
        color: #5b5e71;
      }
    }
  }
}

.search {
  &-filter-button {
    padding: 0 25px;
  }
  &-input {
    width: 270px;
    height: 44px;
    padding: 10px 40px 10px 10px;
    background-color: #f2f3f8;
    border: 1px solid #dadada;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #666670;
    text-indent: 27px;
    &:focus {
      box-shadow: none;
    }
    &-wrapper {
      position: relative;
      mat-icon {
        position: absolute;
        color: #666670;
        top: 11px;
        left: 8px;
      }
    }
  }
}

th.mat-header-cell {
  background: #FFFFFF;
}

.gray-icon {
  color: #ccd2e3;
}
.dark-gray-icon {
  color: #666670;
}

.blue-button {
  color: #6096f6;
}

.red-button {
  color: #f5515f;
}

.toolbar {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 14px 0 0 0;
    height: 40px;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin-top: 1px;
  }

  .mat-form-field-infix {
    border: 0;
  }

  .mat-select-arrow-wrapper {
    height: 3px;
  }
}

.align-right {
  text-align: right;
}

.cdk-overlay-pane {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 96%;
  border-radius: 4px;
}

// tabs
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #f47121;
}

// mat tabs
.mat-tab-label-content {
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  color: #5b5e71;
}
.under-table {
  h3,
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #262626;
    margin-bottom: 0;
  }
}

.mat-card-header-text {
  margin-left: 0 !important;
}

.mat-radio-label-content {
  font-size: 16px;
}

app-instrument-form-tabs {
  .mat-tab-label {
    height: auto;
    min-width: auto;
    padding: 0;
    margin-right: 10px;
    opacity: 1;
    background: transparent !important;

    &.mat-tab-label-active {
      .mat-tab-label-content {
        color: #262626;
        opacity: 1;
      }
    }

    .mat-tab-label-content {
      color: #828282;
      font-weight: 500;
      font-size: 16px;
      opacity: 1;
    }
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 0;
  }
}

.swiper-slide {
  position: relative;

  .image-wrap {
    aspect-ratio : 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centering y-axis */
    align-items :center;

    img {
      width: 100%;
    }
  }

  .file-actions {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 40px;
    width: 84px;
    text-align: right;

    button {
      background: #ffffff60;
      border-radius: 50%;
      margin-left: 4px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

swiper {
  --swiper-navigation-size: 34px;
  --swiper-pagination-bullet-size: 12px;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next,
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  top: auto;
  bottom: 5px;
  z-index: 11;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 12px;
}

.mat-dialog-container {
  position: relative;
}

.no-input-underline {
  .mat-form-field-underline {
    display: none;
  }
}

.no-padding {
  .mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      padding-bottom: 5px !important;
      padding-top: 5px !important;
    }
  }
}
